// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-templates-brand-js": () => import("./../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-item-js": () => import("./../src/templates/item.js" /* webpackChunkName: "component---src-templates-item-js" */)
}

